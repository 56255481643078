import { axiosInstance } from "../../axios";
const GETFindCar = `/api/CarRegistrationLookup/Search/`;
const GETSearchByUser = `/api/CarRegistration/SearchByUser`;
const GETSearchById = `/api/CarRegistration/SearchCarByUser/`;
const GETGetCarByUser = `/api/CarRegistration/GetCarByUser`;
const GETSearchCarByUser = `/api/CarRegistration/SearchCarByUser`;
const POSTSaveCarRegistration = `/api/CarRegistration/CreateCarRegistration`;
const GETSearchPendingCarRegistration = `/api/CarRegistration/SearchPendingCarRegistration`;
const GETSearchProcessingCarRegistration = `/api/CarRegistration/SearchProcessingCarRegistration`;
const GETSearchActiveCarRegistration = `/api/CarRegistration/SearchActiveCarRegistrationByPage`;
const GETSearchActiveCarRegistrationByPurchaseDate = `/api/CarRegistration/SearchActiveCarRegistrationByPurchaseDate`;
const GETSearchAllActiveCarRegistration = `/api/CarRegistration/SearchActiveCarRegistration`;
const GETDownloadSeasonPass = `/api/DownloadPDF/GenerateSeasonPassPDF/`;
const POSTApproveCarRegistration = `/api/CarRegistration/ApproveCarRegistration`;
const POSTDeactivateCarRegistration = `/api/CarRegistration/DeactivateCarRegistration`;
const POSTUpdateCarRegistration = `/api/CarRegistration/UpdateCarDetails`;
const POSTRenewCarRegistration = "/api/CarRegistration/RenewCarRegistration";
const POSTActiveCarRegistration = "/api/CarRegistration/ActiveAllCarRegistration";
const POSTSeasonPassPDF = "/api/DownloadPDF/GenerateAllSeasonPassPDF";
const POSTSearchActiveCarRegistrationByKey = `/api/CarRegistration/SearchActiveCarRegistrationByKey`;
const POSTMarkAsReadRegistration = "api/CarRegistration/MarkAsRead/";
const GETRegistrationCommentCount = "api/CarRegistration/SearchRegistrationCommentCount/"

const findCar = async function(plate, state) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            `${GETFindCar}${plate}/state/${state}`
        );
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};


const searchAllCarRegistration = async function(status, search) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETSearchAllActiveCarRegistration}/${status}/${search}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const searchCarByUser = async function(carId) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            GETSearchCarByUser + carId
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const downloadSeasonPass = async function(carId) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            GETDownloadSeasonPass + carId
        );
        return response.data;
    } catch (error) {
        return null;
    }
};

const SearchCarRegistrationById = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchById + id);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const SearchActiveCarRegistration = async function(page, status, search) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            `${GETSearchActiveCarRegistration}/${page}/status/${status}/search/${search}`
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchActiveCarRegistrationByKey = async function(value) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTSearchActiveCarRegistrationByKey,
            value
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchActiveCarRegistrationByPurchaseDate = async function(status, purchaseDate) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            `${GETSearchActiveCarRegistrationByPurchaseDate}/${status}/purchase/${purchaseDate}`
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchPendingCarRegistration = async function(search) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            `${GETSearchPendingCarRegistration}/${search}`
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchProcessingCarRegistration = async function(search) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(
            `${GETSearchProcessingCarRegistration}/${search}`
        );
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const UpdateCarRegistrationDetails = async function(body) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTUpdateCarRegistration,
            body
        );
        return response.data;
    } catch (error) {
        return null;
    }
};

const SearchByUser = async function(plate, state) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchByUser);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const GetCarByUser = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETGetCarByUser);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SaveCarRegistration = async function(pass) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTSaveCarRegistration,
            pass
        );
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const RenewCarRegistration = async function(pass) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTRenewCarRegistration,
            pass
        );
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const approveCarRegistration = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            `${POSTApproveCarRegistration}/${id}`,
            null
        );
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const deactivateCarRegistration = async function(value) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTDeactivateCarRegistration, value
        );
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const ActiveCarRegistration = async function(body) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTActiveCarRegistration,
            body
        );
        return response.data;
    } catch (error) {
        return [];
    }
};

const downloadAllSeasonPass = async function(body) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(
            POSTSeasonPassPDF,
            body
        );
        return response.data;
    } catch (error) {
        return [];
    }
};

const markAsReadRegistration = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTMarkAsReadRegistration + id);
        let data = parseList(response);
        return data;
    } catch (error) {
        return null;
    }
};

const SearchRegistrationCommentCount = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETRegistrationCommentCount + id);
        // let data = parseList(response);
        // return data;
        return response
    } catch (error) {
        return null;
    }
};

const parseError = error => {
    if (error.response.data.Errors) {
        let stringMessage = error.response.data.Errors.join(" <br/> ");
        error.response.data.Message += "<br/>" + stringMessage;
        return error.response.data;
    } else {
        return error.response.data;
    }
};

const parseList = response => {
    if (response.status === 204) throw Error("Please Select Company");
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

export const carData = {
    findCar,
    SaveCarRegistration,
    GetCarByUser,
    SearchByUser,
    searchCarByUser,
    searchAllCarRegistration,
    SearchActiveCarRegistration,
    SearchActiveCarRegistrationByKey,
    SearchPendingCarRegistration,
    SearchCarRegistrationById,
    approveCarRegistration,
    deactivateCarRegistration,
    UpdateCarRegistrationDetails,
    RenewCarRegistration,
    SearchProcessingCarRegistration,
    ActiveCarRegistration,
    downloadSeasonPass,
    downloadAllSeasonPass,
    SearchActiveCarRegistrationByPurchaseDate,
    markAsReadRegistration,
    SearchRegistrationCommentCount,
};